// src/components/JoinCommunitySection.jsx
import React from "react";

const JoinCommunitySection = () => (
  <section className="py-16 px-6 md:px-16 text-center bg-gray-800">
    <h2 className="text-3xl font-bold mb-10" data-aos="fade-up">
      Join Our Cricket Fantasy Community
    </h2>
    <p className="mb-6 text-lg text-gray-300" data-aos="fade-up" data-aos-delay="100">
      Connect with thousands of cricket fans, share strategies, and compete in weekly challenges. 
      Be part of an active community that celebrates every moment of the game!
    </p>
    <div className="flex flex-col md:flex-row md:justify-center space-y-4 md:space-y-0 md:space-x-6" data-aos="fade-up" data-aos-delay="200">
      <a href="https://wa.link/indraadsgg" className="px-6 py-3 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700">
        Join Our Discord
      </a>
      <a href="https://wa.link/indraadsgg" className="px-6 py-3 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600">
        Follow Us on Twitter
      </a>
      <a href="https://wa.link/indraadsgg" className="px-6 py-3 bg-blue-800 text-white font-semibold rounded hover:bg-blue-900">
        Connect on Facebook
      </a>
    </div>
  </section>
);

export default JoinCommunitySection;
