import React from "react";

const HeroSection = () => (
  <section
    className="relative text-white w-full py-60 px-6 md:px-16 text-center  bg-cover bg-center bg-no-repeat"
    style={{ backgroundImage: "url('/cricket_1101502004.jpg')" }}
  >
    <div className="absolute inset-0 bg-black opacity-60"></div>
    <div className="relative z-10" data-aos="fade-up">
      <h1 className="text-4xl md:text-5xl font-bold mb-4">
      Build Your Own Fantasy Cricket Team – Play for Free.
      </h1>
      <p className="text-lg md:text-xl mb-6">
      Play, Compete, and Win Big in Cricket Fantasy!
      </p>
      <button className="px-6 py-3 bg-yellow-500 text-black font-semibold rounded hover:bg-yellow-600"
        onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}>
        Get Started
      </button>
    </div>
  </section>
);

export default HeroSection;
