// src/components/TestimonialsSection.jsx
import React from "react";

const TestimonialsSection = () => (
  <section className="py-16 px-6 md:px-16 text-center bg-gray-900">
    <h2 className="text-3xl font-bold mb-10" data-aos="fade-up">
      What Our Players Say
    </h2>
    <div className="flex flex-col gap-5 md:flex-row md:justify-around space-y-8 md:space-y-0">
      {[
        {
          quote:
            "I've never had so much fun managing my fantasy team! The rewards make it even better.",
          name: "Amit K.",
          location: "Mumbai, India",
        },
        {
          quote:
            "The competition is thrilling, and I love the real-time scoring based on actual matches.",
          name: "Priya S.",
          location: "Delhi, India",
        },
        {
          quote:
            "A fantastic way to enjoy cricket even more! I’ve learned a lot about players and strategies.",
          name: "Rajesh P.",
          location: "Chennai, India",
        },
      ].map((testimonial, index) => (
        <div
          key={index}
          className="text-left bg-gray-700 p-6 cursor-pointer rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-delay={`${index * 200}`}
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
        >
          <p className="italic mb-4">"{testimonial.quote}"</p>
          <h4 className="text-lg font-semibold">{testimonial.name}</h4>
          <p className="text-gray-400">{testimonial.location}</p>
        </div>
      ))}
    </div>
  </section>
);

export default TestimonialsSection;
