import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "./Herosection";
import FeaturesSection from "./featuresection";
import HowItWorksSection from "./Howitworks";
import RewardsSection from "./Rewards";
import JoinCommunitySection from "./Joincommunity";
import TestimonialsSection from "./Testimonials";
import Footer from "./Footer";



const LandingPage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="font-sans bg-gray-900 text-white">
       <HeroSection/>
       <FeaturesSection/>
       <HowItWorksSection/>
       <RewardsSection/>
       <JoinCommunitySection/>
       <TestimonialsSection/>
       <Footer/>
    </div>
  );
};

export default LandingPage;
