// src/components/Footer.jsx
import React from "react";

const Footer = () => (
  <footer className="py-8 px-6 md:px-16 text-center bg-gray-800">
    <p className="text-sm text-gray-400">
      This is a fantasy sports game for cricket enthusiasts. All rewards are
      skill-based and not affiliated with gambling or betting.
    </p>
    <p className="text-sm text-gray-400 mt-2">© 2024 Cricket Hub</p>
  </footer>
);

export default Footer;
