// src/components/HowItWorksSection.jsx
import React from "react";

const HowItWorksSection = () => (
  <section className="py-16 px-6 md:px-16 text-center bg-gray-800">
    <h2 className="text-3xl font-bold mb-10" data-aos="fade-up">
      How It Works
    </h2>
    <div className="flex flex-col gap-5 md:flex-row md:justify-around space-y-8 md:space-y-0">
      {[
        {
          step: "1. Sign Up",
          description: "Create a free account to start building your dream team.",
          icon: "👤",
        },
        {
          step: "2. Pick Your Team",
          description: "Choose players based on your knowledge and strategy.",
          icon: "🏏",
        },
        {
          step: "3. Compete and Win",
          description: "Score points based on real matches and compete for rewards.",
          icon: "🎉",
        },
      ].map((item, index) => (
        <div
          key={index}
          className="text-left cursor-pointer bg-gray-700 p-6 rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-delay={`${index * 200}`}
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
        >
          <h3 className="text-2xl font-semibold mb-3 flex items-center">
            <span className="mr-2 text-3xl">{item.icon}</span>
            {item.step}
          </h3>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default HowItWorksSection;
