// src/components/RewardsSection.jsx
import React from "react";

const RewardsSection = () => (
  <section className="py-16 px-6 md:px-16 text-center bg-gray-900">
    <h2 className="text-3xl font-bold mb-10" data-aos="fade-up">
      Earn Rewards and Level Up!
    </h2>
    <div className="flex flex-col gap-5 md:flex-row md:justify-around space-y-8 md:space-y-0">
      {[
        {
          title: "Earn Skill-Based Rewards",
          description:
            "Showcase your skills and earn exclusive rewards for top performance.",
          icon: "💎",
        },
        {
          title: "Weekly Challenges",
          description:
            "Take on weekly challenges for a chance to earn bonus points and rewards.",
          icon: "🎉",
        },
        {
          title: "Leaderboard Glory",
          description:
            "Climb the leaderboard and gain recognition among fellow players.",
          icon: "📜",
        },
      ].map((reward, index) => (
        <div
          key={index}
          className="text-left bg-gray-700 cursor-pointer p-6 rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-delay={`${index * 200}`}
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
        >
          <h3 className="text-2xl font-semibold mb-3 flex items-center">
            <span className="mr-2 text-3xl">{reward.icon}</span>
            {reward.title}
          </h3>
          <p>{reward.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default RewardsSection;
