// src/components/FeaturesSection.jsx
import React from "react";

const FeaturesSection = () => (
  <section className="py-16 px-6 md:px-16 text-center bg-gray-800">
    <h2 className="text-3xl font-bold mb-10" data-aos="fade-up">
      Why Join the Fantasy League?
    </h2>
    <div className="flex flex-col gap-4 md:flex-row md:justify-around space-y-8 md:space-y-0">
      {[
        {
          title: "Build Your Dream Team",
          description:
            "Use your cricket knowledge to select players and create a winning team.",
          icon: "🏆",
        },
        {
          title: "Compete with Friends",
          description:
            "Challenge your friends and show them who’s the real cricket expert!",
          icon: "🤝",
        },
        {
          title: "Real-Time Points",
          description:
            "Score points based on real player performance in matches.",
          icon: "📈",
        },
      ].map((feature, index) => (
        <div
          key={index}
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="text-left  bg-gray-700 p-6 rounded-lg shadow-lg cursor-pointer"
          data-aos="fade-up"
          data-aos-delay={`${index * 200}`}
        >
          <h3 className="text-2xl font-semibold mb-3 flex items-center">
            <span className="mr-2 text-3xl">{feature.icon}</span>
            {feature.title}
          </h3>
          <p>{feature.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default FeaturesSection;
